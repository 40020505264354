import { request } from "./base.service";

class MigrationService {
  seed(coords) {
    return request()
              .put(`/_/migrate`, {
                  latitude: coords.latitude, 
                  longitude: coords.longitude,
                  distance: 1,
              })
              .then(result => result.data); 
  }
}

export default new MigrationService();