import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid  } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import RandomAvatar from "../others/random-avatar.component";
import FootDate from "../others/foot-date.component";
import LocationService from "../../services/location.service"
import { useQueryClient } from "react-query";
import userService from "../../services/user.service";
import { useState } from "react";

const doFavorite = (locationId, queryClient, setFavoriteClass) => {
  LocationService.makeFavorite(locationId).then(() => {
    queryClient.invalidateQueries({
      queryKey: LocationService.locationCacheKey('favorites'),
    })

    userService.appendFavoriteToCache(locationId)
    setFavoriteClass("btn-outline-success btn-light bt-sm")
  })
}

const Location = ({location, kind, favorites}) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  let favoriteClasses = favorites.includes(location.ID) ? "btn-outline-success btn-light bt-sm" : "btn-outline-info btn-light bt-sm"
  const [fClazz, setFClazz] = useState(favoriteClasses)

  return <div className="d-flex flex-start mb-4" key={kind+""+location.ID}>
          <RandomAvatar seed={location.ID} width="65" height="65"/>
          <div className="card w-100">
            <div className="card-body p-6">
                <h5>{location.Name}</h5>
                <p>{location.Description}</p>
                <FootDate datetime={location.CreatedAt} clazz="float-start"/>
                <div className="float-end">
                  <Button className={fClazz} onClick={() => { doFavorite(location.ID, queryClient, setFClazz) }}>
                    <FontAwesomeIcon icon={solid('star')} />&nbsp; {location.LocationStats.CommentsCount}
                  </Button>&nbsp;
                  <Button className="btn-outline-info btn-light bt-sm" onClick={()=> navigate(`/locations/${location.ID}`)}>
                    <FontAwesomeIcon icon={solid('comment')} />&nbsp;{location.LocationStats.CommentsCount}
                  </Button>&nbsp;
                  <Button className="btn-outline-info btn-light bt-sm">
                    <FontAwesomeIcon icon={solid('circle-info')} />
                  </Button>
                </div>
              </div>
          </div>
        </div> 
}

export default Location;