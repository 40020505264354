import { Navbar, Container, Nav } from 'react-bootstrap'
import {LinkContainer} from 'react-router-bootstrap'

const HeaderBar = () => {
  return <Navbar bg="light" expand="lg" className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
          <Container fluid={true}>
            <LinkContainer to="/">
              <Navbar.Brand>Noir</Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <LinkContainer to="/locations/general">
                  <Nav.Link>General</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/locations/close">
                  <Nav.Link>Close!</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/locations/favorites">
                  <Nav.Link>Favorites</Nav.Link>
                </LinkContainer>                
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
}

export default HeaderBar;