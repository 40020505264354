import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Button } from "react-bootstrap";
import RandomAvatar from '../others/random-avatar.component';
import FootDate from '../others/foot-date.component';

const Reply = ({reply}) => {
  return <div className="d-flex flex-start mb-4" key={reply.ID}>
          <div style={{width: "100px"}}></div>
          <RandomAvatar seed={reply.UserID} width="65" height="65"/>
          <div className="card w-100">
            <div className="card-body p-6">
              <h5>{reply.Title}</h5>
              <p>{reply.Message}</p>

              <FootDate datetime={reply.CreatedAt} clazz="float-start"/>
              <div className="float-end">
                <Button className="btn-outline-info btn-light bt-sm"><FontAwesomeIcon icon={solid('thumbs-up')} /></Button>&nbsp;
                <Button className="btn-outline-info btn-light bt-sm"><FontAwesomeIcon icon={solid('flag')} /></Button>
              </div>
            </div>
          </div>
        </div>
}

export default Reply;