import { Button, Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import locationService from "../../services/location.service";
import RandomAvatar from '../others/random-avatar.component';
import FootDate from '../others/foot-date.component';
import userService from '../../services/user.service';

const CommentHeader = () => {
  const favorites = userService.cachedFavorites()
  const { locationId } = useParams()
  const { data, isLoading } = useQuery({
    queryKey: ['location', locationId],
    queryFn: async () => locationService.fetch(locationId)
  });

  let favoriteClasses = "btn-outline-info btn-light bt-sm"

  if(favorites.includes(locationId)) {
    favoriteClasses = "btn-outline-success btn-light bt-sm"
  }

  if(isLoading) {
    return <div>Loading</div>
  } else {
    return <div className="d-flex border rounded" style={{'backgroundColor': 'white'}} key="comment-main">
            <Row>
              <Col className="col-1 d-flex p-4 justify-content-center">
                <RandomAvatar seed={data.ID} width="105" height="105"/>
              </Col>
              <Col className="col-11 p-3 px-4">
                <Row>
                  <Col>
                    <strong>{data.Name}</strong><br/><span>{data.Description}</span>
                  </Col>                  
                </Row>
                <Row>
                  <Col className='pt-3'>
                    <FootDate datetime={data.CreatedAt} clazz="float-start"/>
                    <div className="float-end">
                      <Button className={favoriteClasses}><FontAwesomeIcon icon={solid('star')} /></Button>&nbsp;
                      <Button className="btn-outline-info btn-light bt-sm">
                        <FontAwesomeIcon icon={solid('comment')} />&nbsp;10
                      </Button>&nbsp;
                      <Button className="btn-outline-info btn-light bt-sm"><FontAwesomeIcon icon={solid('flag')} /></Button>&nbsp;
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
  }
}

export default CommentHeader;
