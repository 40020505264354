import ReactImageFallback from 'react-image-fallback';

const RandomAvatar = ({seed, width, height}) => {
    return <ReactImageFallback
            src={`https://imagedelivery.net/F2pKZVNAGGSsHWAXknA7QA/${btoa(seed)}/thumb`}
            fallbackImage={`https://api.dicebear.com/5.x/pixel-art/svg?seed=${seed}&flip=false&xradius=0`}
            initialImage="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
            className="rounded-circle shadow-1-strong me-3" 
            width={width}
            height={height}/>
}

export default RandomAvatar;