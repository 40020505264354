import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import locationService from "../../services/location.service";
import Reply from "./reply.component";

const ReplyList = () => {
  const { locationId, commentId } = useParams();
  const { isLoading, data } = useQuery({
    queryKey: ['replies', commentId],
    queryFn: async () =>
      locationService.fetchRepliesByComment(locationId, commentId)
  });

  return <>
          {isLoading ? 
            <p>Loading</p> : 
            data.map((reply) => <Reply reply={reply} key={reply.ID}/>)}
         </>
}

export default ReplyList;