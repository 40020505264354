import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import commentService from "../../services/comment.service";
import Comment from "../comment/comment.component";

const ReplyHeader = () => {
  const { commentId } = useParams()
  const { data, isLoading } = useQuery({
    queryKey: ['comment', commentId],
    queryFn: async () => commentService.fetch(commentId)
  });

  if(isLoading) {
    return <div>Loading</div>
  } else {
    return <Comment comment={data}/>
  }
}

export default ReplyHeader  ;
