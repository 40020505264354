import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import locationService from "../../services/location.service";
import Comment from "./comment.component";

const CommentList = () => {
  const {locationId} = useParams();

  const { isLoading, data } = useQuery({
    queryKey: ['comments', locationId],
    queryFn: async () =>
      locationService.fetchCommentsByLocation(locationId)
    });

  return  <div className="my-4">
            { isLoading ? <p>Loading</p> : data.map((comment) => <Comment comment={comment} key={comment.ID}/>) }
          </div>
}

export default CommentList;