import { request, currentServerId  } from "./base.service";

class LocationService {
  FAVORITE_CACHE_KEY = ['locations', 'favorite']
  CLOSETOME_CACHE_KEY = ['locations', 'close']
  GENERAL_CACHE_KEY = ['locations', 'general']

  locations() {
    return request().get(`/locations/general`).then(result => result.data);
  }

  close(coords) {
    return request()
              .get(`/locations/close`, {
                params: {
                  latitude: coords.latitude, 
                  longitude: coords.longitude,
                  distance: 1,
              }})
              .then(result => result.data); 
  }

  favorites() {
    return request().get(`/locations/favorites`).then(result => result.data); 
  }

  makeFavorite(locationId) {
    return request().put(`/locations/${locationId}/favorite`, {}).then(response => response.data);
  }

  fetch(locationId) {
    return request().get(`/locations/${locationId}`).then(response => response.data);
  }

  fetchCommentsByLocation(locationId) {
    return request().get(`/locations/${locationId}/comments`).then(response => response.data);
  }
  
  fetchRepliesByComment(locationId, commentId) {
    return request().get(`/locations/${locationId}/comments/${commentId}/replies`).then(response => response.data);
  }

  locationCacheKey(kind) {
    if (kind === "general") {
      return [currentServerId(), ...this.GENERAL_CACHE_KEY];
    } else if (kind === "close") {
      return [currentServerId(), ...this.CLOSETOME_CACHE_KEY];
    } else if (kind === "favorites") {
      return [currentServerId(), ...this.FAVORITE_CACHE_KEY];
    }

    return null;
  }
}

export default new LocationService();