import { Button } from "react-bootstrap";
import UserService from "../../services/user.service";
import { useNavigate } from 'react-router-dom';
import { setCurrentServerId } from "../../services/base.service";
import { Geolocated, GeolocatedSeedButton } from "../location/geolocated.component";

const doLogin = (navigate) => {
  UserService.login("admin", "admin").then(response => {
    navigate("/locations/general");
  }).then(_ => {
    UserService.favorites()
  })
};

const setServer = (serverId, navigate) => {
  setCurrentServerId(serverId)
  doLogin(navigate)
};

const Home = () => {
  const navigate = useNavigate();

  return <div className="d-flex flex-start mb-4">
            <Button onClick={() => doLogin(navigate)}>Login</Button>&nbsp;
            <Button onClick={() => setServer("A", navigate)}>Server A</Button>&nbsp;
            <Button onClick={() => setServer("B", navigate)}>Server B</Button>&nbsp;
            <Geolocated>
              <GeolocatedSeedButton/>
            </Geolocated>
         </div>
}

export default Home;