import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import RandomAvatar from '../others/random-avatar.component';
import FootDate from '../others/foot-date.component';

const Comment = ({comment}) => {
  const navigate = useNavigate();

  return <div className="d-flex flex-start mb-4" key={comment.ID}>
          <RandomAvatar seed={comment.UserID} width="65" height="65"/>
          <div className="card w-100">
            <div className="card-body p-6">
              <h5>{comment.Title}</h5>
              <p>{comment.Message}</p>
              <FootDate datetime={comment.CreatedAt} clazz="float-start"/>
              <div className="d-flex flex-row float-end">
                <Button className="btn-outline-info btn-light bt-sm">
                  <FontAwesomeIcon icon={solid('thumbs-up')} />&nbsp;{comment.CommentStats.RepliesCount}
                </Button>&nbsp;
                <Button className="btn-outline-info btn-light bt-sm" onClick={()=> navigate(`/locations/${comment.LocationID}/comments/${comment.ID}`)}>
                  <FontAwesomeIcon icon={solid('comment')} />&nbsp;{comment.CommentStats.RepliesCount}
                </Button>&nbsp;
                <Button className="btn-outline-info btn-light bt-sm">
                  <FontAwesomeIcon icon={solid('info')} />
                </Button>
              </div>              
            </div>
          </div>
        </div>
}

export default Comment;