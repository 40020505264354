import axios from "axios";
import UserService from "./user.service";

const BaseAPIUrl = (() => {
  if(process.env.REACT_APP_NOIR_ENVIRONMENT !== "production") {
    return {
      "A": "http://localhost:8081/v1/api",
      "B": "http://localhost:8080/v1/api",
    }
  } else {
    return { 
      "A": "https://noir-a.dp42.io/v1/api",
      "B": "https://noir-b.dp42.io/v1/api",
    }
  }
})();

const currentServerId = () => {
  return localStorage.getItem('serverId') || "A";
}

const setCurrentServerId = (serverId) => {
  return localStorage.setItem('serverId', serverId);
}

const request = () => {
  return axios.create({
    baseURL: BaseAPIUrl[currentServerId()],
    headers: { Authorization: `Bearer ${UserService.token()}`}
  })
}

const unauthenticatedRequest = () => {
  return axios.create({
    baseURL: BaseAPIUrl[currentServerId()],
  });
}

export { unauthenticatedRequest, request, setCurrentServerId, currentServerId }