import { useGeolocated } from "react-geolocated";
import LocationMain from "./location-main.component";

const GeolocatedLocationMain = () => {
  const geocode = useGeolocated({
    userDecisionTimeout: 5000,  
    positionOptions: {
        enableHighAccuracy: false,
      }
  });

  return geocode.coords === undefined ? 
          <p>Loading</p> : 
          <LocationMain kind="close" params={geocode.coords}/>
}

export default GeolocatedLocationMain;