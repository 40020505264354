import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import locationService from "../../services/location.service";
import CommentHeader from "../comment/comment-header.component";
import ReplyHeader from "./reply-header.component";
import ReplyList from "./reply-list.component";

const ReplyMain = () => {
  const { locationId } = useParams();
  const { isLoading, data } = useQuery({
    queryKey: ['comments', locationId],
    queryFn: async () =>
      locationService.fetchCommentsByLocation(locationId)
  });

  return <>
          <CommentHeader/>
            {isLoading ? 
              <div>Loading</div> :
              <div className="my-4">
                <ReplyHeader comment={data[0]}/>
                <ReplyList/>
              </div>}
         </>;
}

export default ReplyMain;