import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route } from "react-router-dom";

import CommentMain from './components/comment/comment-main.component'
import LocationMain from './components/location/location-main.component'; 
import Home from './components/others/home.component';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";

import { Container } from 'react-bootstrap'
import { QueryClient, QueryClientProvider } from 'react-query'
import ReplyMain from './components/reply/reply-main.component';
import HeaderBar from './components/others/header-bar.component';
import GeolocatedLocationMain from './components/location/geolocated-location-main.component';

const queryClient = new QueryClient({ 
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    }
  }
})

ReactDOM.createRoot(document.getElementById('targetContainer')).render(
  <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <HeaderBar/>
          <Container fluid={true} style={{"marginTop":"80px"}}>
            <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/locations/general" element={<LocationMain kind="general"/>} />
              <Route path="/locations/close" element={<GeolocatedLocationMain kind="favorites"/>} />
              <Route path="/locations/favorites" element={<LocationMain kind="favorites"/>} />
              <Route path="/locations/:locationId" element={<CommentMain/>} />
              <Route path="/locations/:locationId/comments/:commentId" element={<ReplyMain/>} />
            </Routes>
          </Container>
        </BrowserRouter>
      </QueryClientProvider>    
  </React.StrictMode>
);

reportWebVitals();