import CommentList from "./comment-list.component";
import CommentHeader from "./comment-header.component";

const CommentMain = () => {
    return <>
            <CommentHeader/>
            <CommentList/>
          </>;
}

export default CommentMain;
