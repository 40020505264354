import { useGeolocated } from "react-geolocated";
import React, { createContext, useContext } from 'react';
import { Button } from "react-bootstrap";
import migrationService from "../../services/migration.service";

const GeolocatedContext = createContext();

const Geolocated = ({children}) => {
  const geocode = useGeolocated({
    userDecisionTimeout: 5000,  
    positionOptions: {
        enableHighAccuracy: false,
      }
  });

  return geocode.coords === undefined ? 
          <p>Loading</p> : 
          <GeolocatedContext.Provider value={geocode.coords}>
            {children}
          </GeolocatedContext.Provider>
}

const GeolocatedSeedButton = () => {
  const coords = useContext(GeolocatedContext);

  return <GeolocatedContext.Provider value={coords}>
            <Button onClick={() => migrationService.seed(coords)}>Seed</Button>
         </GeolocatedContext.Provider>
}

export {Geolocated, GeolocatedContext, GeolocatedSeedButton};