import { Col, Row } from "react-bootstrap";
import LocationList from "./location-list.component";
import LocationService from "../../services/location.service"
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import userService from "../../services/user.service";

const fetchLocations = (kind, params) => {
  if (kind === "favorites") {
    return LocationService.favorites()
  } else if (kind === "general") {
    return LocationService.locations()
  } else if (kind === "close") {
    return LocationService.close(params)
  }
}

const LocationMain = ({kind, params}) => {
  const favorites = userService.cachedFavorites()
  const navigate = useNavigate();
  const { data, isLoading } = useQuery({
    queryKey: LocationService.locationCacheKey(kind),
    queryFn: async () => fetchLocations(kind, params),
    onError: () => { navigate("/") }
  });

  return <Row style={{height: "100%"}}>  
          <Col lg="12">
            <LocationList locations={data} favorites={favorites} isLoading={isLoading} kind={kind}/>
          </Col>
         </Row>
}

export default LocationMain;