import { unauthenticatedRequest, request } from "./base.service";

class UserService {
  login(username, password) {
    return unauthenticatedRequest()
      .post("/check", { username, password })
      .then(response => {
        if (response.data.token) {
          this.updateLocalStore('user', response.data)
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  currentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  cachedFavorites() {
    return JSON.parse(localStorage.getItem('favorites'));
  }

  appendFavoriteToCache(favoriteId) {
    this.updateLocalStore('favorites', [...this.cachedFavorites(), favoriteId]);
  }

  token() {
    return this.currentUser()["token"];
  }

  updateLocalStore(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  favorites() {
    request().get("/users/favorites").then(response => {
      this.updateLocalStore('favorites', response.data)
      return response.data;
    });
  }
}

export default new UserService();